.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2, h3 {
  color: #2c3e50;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

h2 {
  font-size: 1.8em;
  margin-top: 30px;
}

p {
  margin-bottom: 20px;
}

.policy-links {
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.policy-links h3 {
  color: #2980b9;
}

.policy-links ul {
  list-style-type: none;
  padding: 0;
}

.policy-links li {
  margin-bottom: 10px;
}

.policy-links a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.policy-links a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.footer {
  margin-top: 40px;
  text-align: center;
  color: #7f8c8d;
  font-size: 0.9em;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}
